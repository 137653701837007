<template>
  <section class="section-main">
    <ConnectFailedMessage v-if="isFailed" />
    <div class="section-main__action" v-if="isFailed">
      <button
        type="button"
        @click="handleClick()"
        class="button is-fullwidth is-fill is-rounded"
      >
        回到首頁
      </button>
    </div>
    <IsPreparingMessage v-if="!isFailed" />
  </section>
</template>
<script>
import connectorAPI from "../../apis/connector";
import chargingAPI from "../../apis/charging";
import { mapState } from "vuex";
import ConnectFailedMessage from "../Messages/ConnectFailedMessage.vue";
import IsPreparingMessage from "../Messages/IsPreparingMessage.vue";
const connectorStatus = {
  Available: 1,
  Unavailable: 2,
  Preparing: 3,
  Charging: 4,
  SuspendedEVSE: 5,
  SuspendedEV: 6,
  Finishing: 7,
  Faulted: 8,
  Remove: 9,
  Reserved: 10,
};
export default {
  name: "CheckConnector",
  data() {
    return {
      timer: null,
      time: 120,
      status: 0,
      isFailed: false,
    };
  },
  components: { ConnectFailedMessage, IsPreparingMessage },
  mounted() {
    const { connectorId } = this.$route.params;
    this.checkConnectorState(connectorId);
    if (this.status !== connectorStatus.Preparing) {
      this.timer = setInterval(this.countdown, 10 * 1000);
    } else {
      this.startCharging(this.charging.chargingId);
    }
  },
  methods: {
    countdown() {
      this.time = this.time - 10;
      const { connectorId } = this.$route.params;
      this.checkConnectorState(connectorId);
      if (this.time === 0) {
        this.isFailed = true;
        clearInterval(this.timer);
        this.timer = null;
      }
      if (this.status === connectorStatus.Preparing) {
        clearInterval(this.timer);
        this.timer = null;
        this.startCharging(this.charging.chargingId);
      }
    },
    async checkConnectorState(connectorid) {
      try {
        const { connectorId, stationId } = this.$route.params;
        const { data, status } = await connectorAPI.connector.getStatus({
          connectorid,
        });
        if (!(status >= 200 && status < 300)) {
          this.$router.replace({
            name: "ErrorHint",
            params: {
              stationId: stationId,
              connectorId: connectorId,
            },
          });
          throw new Error(status);
        }
        this.status = data.status;
      } catch (error) {
        console.log(error);
        const { connectorId, stationId } = this.$route.params;
        this.$router.replace({
          name: "ErrorHint",
          params: {
            stationId: stationId,
            connectorId: connectorId,
          },
        });
      }
    },
    async startCharging(chargingid) {
      try {
        const { connectorId, stationId } = this.$route.params;
        const { status } = await chargingAPI.charging.startCharging({
          chargingid,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        this.$router.replace({
          name: "Charging-progress",
          params: {
            chargingId: this.charging.chargingId,
            stationId: stationId,
            connectorId: connectorId,
          },
        });
      } catch (error) {
        console.log(error);
        const { connectorId, stationId } = this.$route.params;
        this.$router.replace({
          name: "ErrorHint",
          params: {
            stationId: stationId,
            connectorId: connectorId,
          },
        });
      }
    },
    handleClick() {
      this.$router.replace({
        name: "Connector",
        params: { connectorId: this.$route.params.connectorId },
      });
    },
  },
  computed: {
    ...mapState(["charging"]),
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
