<template>
  <div class="section-main__content">
    <inline-svg
      :src="require('../../assets/icon/svg/loading.svg')"
      class="loading"
    />
    <div class="section-main__title"><h1>充電裝置正在準備中</h1></div>
    <p>請稍等幾秒鐘</p>
  </div>
</template>
<script>
export default {
  name: "IsPreparingMessage",
};
</script>
